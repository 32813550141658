import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,7];

export const dictionary = {
		"/(app)": [~20,[3],[4]],
		"/(app)/about/become-a-driver": [~22,[3],[4]],
		"/(app)/about/become-a-restaurant-partner": [23,[3],[4]],
		"/(app)/about/careers": [24,[3],[4]],
		"/(app)/about/contact": [25,[3],[4]],
		"/(app)/about/faq": [~26,[3],[4]],
		"/(app)/about/groceries": [27,[3],[4]],
		"/(app)/about/snackme": [28,[3],[4]],
		"/(app)/about/snackme/friend": [29,[3],[4]],
		"/(app)/about/snackme/gift": [30,[3],[4]],
		"/(app)/about/snackme/gift/request": [31,[3],[4]],
		"/(app)/about/takealotmore": [32,[3],[4]],
		"/(app)/buy-again": [33,[3],[4]],
		"/(admin)/cms": [9,[2]],
		"/(admin)/cms/areas": [10,[2]],
		"/(admin)/cms/brands": [11,[2]],
		"/(admin)/cms/editable/home": [12,[2]],
		"/(admin)/cms/images": [13,[2]],
		"/(admin)/cms/inserts": [14,[2]],
		"/(admin)/cms/map": [~15,[2]],
		"/(admin)/cms/order/[id]": [~16,[2]],
		"/(admin)/cms/restaurants": [17,[2]],
		"/(admin)/cms/settings": [18,[2]],
		"/(admin)/cms/tags": [19,[2]],
		"/(app)/deals_coupons_discounts_promotions_codes": [~34,[3],[4]],
		"/(app)/delivery/best-restaurants": [~37,[3],[4,6]],
		"/(app)/delivery/in/[...area]": [~38,[3],[4,6]],
		"/(app)/delivery/of/[tag]": [~39,[3],[4,6]],
		"/(app)/delivery/restaurants-near-me/[[page]]": [~40,[3],[4,6]],
		"/(app)/delivery/[vertical=seoVertical]/[slug]-near-me": [~35,[3],[4,6]],
		"/(app)/delivery/[vertical=vertical]/[name]/[id]": [~36,[3],[4,6]],
		"/env": [61],
		"/env/platform": [62],
		"/env/push": [63],
		"/env/releasenotes": [64],
		"/(app)/faq": [41,[3,7],[4]],
		"/(app)/faq/articles/[id]": [42,[3,7],[4]],
		"/(app)/faq/sections/[id]": [43,[3,7],[4]],
		"/(app)/gifting": [44,[3],[4]],
		"/(app)/gifting/gifts": [45,[3],[4]],
		"/(app)/id": [46,[3],[4]],
		"/(app)/info/[page]": [~47,[3],[4]],
		"/(app)/profile": [48,[3,8],[4]],
		"/(app)/profile/cards": [49,[3,8],[4]],
		"/(app)/profile/me": [50,[3,8],[4]],
		"/(app)/profile/orders": [51,[3,8],[4]],
		"/(app)/profile/smartshopper": [52,[3,8],[4]],
		"/(app)/profile/wallet": [53,[3,8],[4]],
		"/(app)/reset-password/[token]": [54,[3],[4]],
		"/(app)/signin": [55,[3],[4]],
		"/(app)/simulate/[vertical=vertical]/order/details": [56,[3],[4]],
		"/(app)/styleguide": [57,[3],[4]],
		"/(order)/[vertical=vertical]/order/[id]/checkout": [58],
		"/(order)/[vertical=vertical]/order/[id]/checkout/[outcome]": [59],
		"/(order)/[vertical=vertical]/order/[id]/driver": [60],
		"/(app)/[vertical]/order/[id]/details": [21,[3,5],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';